import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import IndexLayout from '../layouts'
import { appendUrlPartnerTracking, getItem } from '../util/functions'
import FormDivider from '../components/FormDivider'
import theme from '../style/color'
import Btn from '../components/BtnForm'

const IndexPage = () => {
  useEffect(() => {
    if (!getItem('email') || !getItem('accountNumber') || !getItem('subdomain')) {
      window.location.href = appendUrlPartnerTracking('/account-details')
    }
    window.sessionStorage.clear()
  }, [])

  const [resentEmail, toggleResentEmail] = useState(false)
  const [email, setEmail] = useState(getItem('email'))
  const [subdomain, setDomain] = useState(getItem('subdomain'))

  const resendEmail = () => {
    toggleResentEmail(true)
  }

  return (
    <IndexLayout
      currentPage={3}
      heading={resentEmail ? "You've got mail!" : 'Get ready to learn'}
      body=""
      resentEmail={resentEmail}
      title="Confirmation - Bob's Business"
    >
      <ConfirmContainer>
        <ConfirmCard>
          <FormDivider heading="Let's activate your account" />
          <p>
            Congratulations, your learning platform is now available at{' '}
            <a
              onClick={() => (window.location.href = `https://${subdomain}.cyberlearn.app`)}
              target="_blank"
            >{`https://${subdomain}.cyberlearn.app`}</a>
            .
          </p>
          <p>
            We’ve sent an activation email to <strong>{email}</strong> with a link to activate your account.
          </p>
          {/*
            {resentEmail ? (
            <div>
            <p>Still having issues?</p>
            <p>
            If you still can’t see it in your inbox, please contact us on <a href="tel:0330 583009">0330 583009</a> or email us on{' '}
            <a href="mailto:email@address.com">email@address.com</a> and quote your customer number <strong>{accountNumber}</strong>.
            </p>
            </div>
          ) : (
          <div>
          <p>Didn't receive the email?</p>
          <p>Check your spam folder or...</p>
          <Btn onClick={resendEmail} type="secondary">
          <>
          <strong>Resend</strong> Email
          </>
          </Btn>
          </div>
        )}
            */}
        </ConfirmCard>
      </ConfirmContainer>
    </IndexLayout>
  )
}

const ConfirmContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 82px;
  display: flex;
  align-items: center;
  justify-content; center;

  @media only screen and (max-width: 850px) {
    padding-bottom: 0;
    padding-top: 0;
  }
`

const ConfirmCard = styled.div`
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 40px;
  margin: 0 auto;
  width: 570px;

  & a {
    text-decoration: underline;
    cursor: pointer;
    color: ${theme.primary};

    &:hover {
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 850px) {
    width: 100%;
    box-shadow: none;
  }

  & > p {
    color: ${theme.text};
    margin-top: 17px;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;

    & strong {
      font-weight: bold;
    }

    @media only screen and (max-width: 850px) {
      font-size: 15px;
      line-height: 25px;
    }
  }

  & > div:nth-of-type(2) {
    margin-top: 26px;
    & > p {
      color: ${theme.text};
      font-size: 15px;
      font-weight: bold;
      line-height: 25px;
      &:nth-of-type(1) {
        font-weight: bold;
      }
      &:nth-of-type(2) {
        font-weight: 300;
        margin-top: 7px;
        margin-bottom: 16px;

        & a {
          font-size: inherit;
        }
      }
    }
  }
`

export default IndexPage
